import { graphql, navigate, PageProps } from "gatsby"
import { HelmetDatoCmsTags } from "gatsby-source-datocms"
import React, { useEffect } from "react"
import DefaultLayout from "../components/layouts/DefaultLayout"
import HeroImage from "../components/organisms/HeroImage"

const Page = ({ data }: PageProps<Queries.IndexPageQuery>) => {
  useEffect(() => {
    function getNavigatorLanguage(): string[] | undefined {
      const found: string[] = []

      if (typeof navigator !== "undefined") {
        if (navigator.languages) {
          // chrome only; not an array, so can't use .push.apply instead of iterating
          for (let i = 0; i < navigator.languages.length; i++) {
            found.push(navigator.languages[i])
          }
        }
        // for IE
        // if (navigator.userLanguage) {
        //   found.push(navigator.userLanguage)
        // }
        if (navigator.language) {
          found.push(navigator.language)
        }
      }

      return found.length > 0 ? found : undefined
    }
    const langs = getNavigatorLanguage()
    if (langs) {
      const lang = langs[0].substring(0, 2)
      if (lang === "fi" || lang === "sv") {
        navigate(`/${lang}`)
      } else {
        navigate("/fi")
      }
    }
  }, [])

  const globalSeo = data.datoCmsSite?.globalSeo
  const page = data.datoCmsFrontpage

  if (!page) {
    return null
  }

  const seoMetaTags: HelmetDatoCmsTags = {
    tags: [
      {
        tagName: "title",
        content: globalSeo?.siteName || "",
      },
      {
        tagName: "description",
        content: page.ingress || "",
      },
    ],
  }

  return (
    <DefaultLayout mainNav={data.mainNavData} seoMetaTags={seoMetaTags}>
      <HeroImage image={page.image} />
      <h1>{page.title}</h1>
      <p>{page.ingress}</p>
    </DefaultLayout>
  )
}

export default Page

export const query = graphql`
  query IndexPage {
    datoCmsSite {
      globalSeo {
        fallbackSeo {
          title
          description
          image {
            #fluid(imgixParams: { fit: "crop", w: "2000", h: "500" }, maxWidth: 2000) {
            #  ...GatsbyDatoCmsFluid
            #}
            gatsbyImageData(layout: FULL_WIDTH, width: 2000, height: 500)
          }
        }
        siteName
        titleSuffix
        twitterAccount
      }
    }
    datoCmsFrontpage(id: { eq: "DatoCmsFrontpage-7562325-fi" }) {
      title
      ingress
      image {
        ...DatoHeroImage
      }
    }
    mainNavData: datoCmsRoute(slug: { eq: "fi" }) {
      ...DatoMainNavTree
    }
  }
`
